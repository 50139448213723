<template>
  <b-card body-class="p-0" header-class="border-0">
    <template v-slot:header>
      <b-row align-v="center">
        <b-col sm="12" md="3" lg="4">
          <h3 class="mb-0 header-text">Thrive Offers</h3>
        </b-col>
        <b-col cols="6" md="1" lg="4"></b-col>
        <b-col sm="8" md="8" lg="4">
          <b-form-group
            id="search-wrapper"
            label-for="input-1"
            v-bind:class="{ active: isActive, focused: isFocused }"
          >
            <b-form-input
              id="filter-input"
              ref="filterinput"
              type="text"
              placeholder="Search trader's data"
              v-model="query"
              @keyup="filterTableBySearch"
              @blur.native="removeCancelSearchIcon"
              @focus="focused"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </template>
    <spinner v-if="isLoading"></spinner>

    <!-- Table -->
    <el-table
      id="thriveDataTable"
      class="table-responsive table"
      :data="displayData"
      header-row-class-name="thead-light"
      v-if="!isLoading"
      @sort-change="sortChange"
    >
      <el-table-column
        label="Thrive Id"
        min-width="90px"
        prop="thriveId"
        sortable
      >
        <template v-slot="{ row }">
          <div class="font-weight-600">{{ row.traderId }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="Created"
        min-width="90px"
        prop="dateCreated"
        sortable
      >
        <template v-slot="{ row }">
          {{ row.dateCreated }}
        </template>
      </el-table-column>
      <el-table-column
        label="Expires"
        min-width="90px"
        prop="expiryDate"
        sortable
      >
        <template v-slot="{ row }">
          {{ row.expiryDate }}
        </template>
      </el-table-column>
      <el-table-column label="Status" min-width="90px" prop="status" sortable>
      </el-table-column>

      <el-table-column label="Term" min-width="50px" prop="term" sortable>
      </el-table-column>

      <el-table-column
        label="Amount"
        min-width="90px"
        :formatter="currencyFormatter"
        prop="maxloan"
        sortable
      >
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="currentPage"
      @current-change="handleCurrentChange"
      :total="rows"
      :page-size="pageSize"
      align="right"
      v-if="!isLoading"
    >
    </el-pagination>
  </b-card>
</template>
<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Pagination,
} from "element-ui";
import config from "../../config";
import Spinner from "../../components/Spinner.vue";
import axios from "axios";
export default {
  name: "thriveData-table",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Pagination.name]: Pagination,
    Spinner,
  },
  data() {
    return {
      isActive: false,
      isFocused: false,
      currentPage: 1,
      pageSize: 10,
      isLoading: false,
      syncLoading: false,
      sortValue: "",
      businessName: "",
      businessId: "",
      clientId: "",
      depositAccountId: "",
      walletBalance: 0.0,
      formattedBalance: "",
      isModalVisible: false,
      isSyncModalVisible: false,
      isConfirmationVisible: false,
      clientName: "",
      syncSuccess: null,
      errorMessage: "",
      query: "",
      cards: [],
      masterData: [],
      tableData: [
        {
          thriveId: "0",
          date: "2024-01-01",
          transactionType: "Unknown",
          debitOrCredit: "C",
          amount: 0.0,
          traderType: "Trader",
          region: "Gauteng",
          hasPosDevice: 0,
        },
      ],
    };
  },
  computed: {
    rows() {
      return this.tableData.length;
    },
    displayData() {
      console.log("getting display data");
      if (!this.tableData || this.tableData.length === 0) return [];
      return this.tableData.slice(
        this.pageSize * this.currentPage - this.pageSize,
        this.pageSize * this.currentPage
      );
    },
  },
  methods: {
    sortChange($event) {
      // Get sort change data
      const { prop, order } = $event;
      console.log($event);
      // Check for sort order and table data accordingly
      if (order == "ascending") {
        this.tableData = this.tableData.sort((a, b) =>
          a[prop] > b[prop] ? 1 : -1
        );
      }

      if (order == "descending") {
        this.tableData = this.tableData.sort((a, b) =>
          a[prop] < b[prop] ? 1 : -1
        );
      }
      console.log("Table data:", this.tableData);
    },
    filterTableBySearch() {
      // Get all clients from store
      const traders = this.masterData;
      // Filter clients by search query
      const filteredTraders = traders.filter(
        (c) =>
          c.traderId.toLowerCase().includes(this.query.toLowerCase()) ||
          c.maxloan.toLowerCase().includes(this.query.toLowerCase())
      );
      // Store the filtered data as the new tatble data values
      this.tableData = filteredTraders;
    },
    resetTableData() {
      // When query length is zero input is in empty state, we get client from store
      // Store clients from store and update table data
      this.tableData = this.masterData;
    },
    cancelFilterBySearch() {
      // Get the search input and set it to null/empty
      const input = (this.$refs.filterinput.$el.value = "");
      // Remove focused class
      this.isFocused = false;
      // Remove active class
      this.isActive = false;
      // Reset table data
      this.resetTableData();
    },
    removeCancelSearchIcon($event) {
      // Get typed in value in input
      const queryLength = $event.target.value.trimStart().length;
      // Remove cancel icons based on length value length
      if (queryLength == 0) {
        // Reverse is active
        this.isActive = false;
        // Reverse is focused
        this.isFocused = false;
      }
    },
    focused() {
      // Set focused class on search input wrapper
      this.isFocused = true;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    currencyFormatter(row, column, cellValue) {
      return this.formatCurrency(cellValue);
    },
    formatCurrency(cellValue) {
      return cellValue != null
        ? parseFloat(cellValue).toLocaleString("en-ZA", {
            style: "currency",
            currency: "ZAR",
          })
        : (0).toLocaleString("en-ZA", { style: "currency", currency: "ZAR" });
    },
    percentageFormatter(row, column, cellValue) {
      return this.formatPercentage(cellValue);
    },
    formatPercentage(cellValue) {
      return (
        (isNaN(parseFloat(cellValue)) ? 0 : parseFloat(cellValue).toFixed(2)) +
        " %"
      );
    },
  },
  mounted() {
    this.isLoading = true;
    var http = this.axios.create({
      baseURL: config.apiUrl,
      timeout: 30000,
      headers: { Authorization: this.$store.getters["accounts/token"] },
    });
    http.get("/data/offers").then((response) => {
      // Store retrieved clients in table data
      //console.log("Response data:", response.data);
      this.tableData = response.data;
      this.masterData = response.data;
      // Store retrived clients in store
      //this.$store.dispatch("thriveData/addTraders", response.data);
      this.handleCurrentChange(1);
      this.isLoading = false;
    });
  },
};
</script>

<style scoped>
#search-wrapper {
  position: relative;
  margin: 0;
}

.cta {
  text-align: center;
}

#search-wrapper.focused input {
  border-color: #f7941d;
}

#search-wrapper.active .clear-search {
  display: flex;
}

#search-wrapper .clear-search {
  padding-top: 2px;
  position: absolute;
  top: 50%;
  right: 15px;
  display: flex;
  width: 20px;
  height: 20px;
  background: #f1f1f1;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: none;
}
#search-wrapper .clear-search i {
  font-size: 13px;
  line-height: none;
  padding: 0;
  margin: 0;
  color: #525f7f;
}

.devices {
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  list-style: none;
  padding: 0 5px;
  border-bottom: 1px solid #ebeef5;
  margin-top: 15px;
  display: inline-block;
  width: 100%;
  margin-bottom: 35px;
}

.devices .device {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ebeef5;
  padding: 10px 0;
}

.devices .device button {
  color: #fff;
  background-color: #f1f1f1;
  border-color: #f7941d;
  outline: 0;
  border: 0;
  width: 30px;
  height: 30px;
  border-radius: 4px;
}

.devices .device button i {
  color: #f7941d;
}

.message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0 50px;
}

.message-icon {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  border-radius: 50%;
  margin: 10px auto;
  margin-bottom: 10px;
}

.message.delete {
  margin-top: 10px;
  margin-bottom: 10px;
}

.message.delete .message-icon {
  background: #f5f5f5;
}

.message.delete .message-icon i {
  color: #f7941d;
  margin-top: -4px;
}

.message-icon i {
  font-size: 30px;
}

.message span {
  color: #525f7f;
  font-size: 15px;
  display: inline-block;
  margin-bottom: 20px;
}

.success-message {
  text-align: center;
  margin: 0 auto;
  margin-top: 10px;
}

.success-message p {
  color: #f7941d;
  margin-top: 15px;
}
.icon-wrapper {
  width: 60px;
  height: 60px;
  background: #f7941d;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 5px;
}

.icon-wrapper i {
  font-size: 30px;
  color: #fff;
}
</style>
